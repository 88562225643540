export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CHECKOUT':
      return {
        ...state,
        checkout: action.checkout || state.checkout,
        isAdding: true,
        currentLineItem: action.currentLineItem,
      };
    case 'REMOVE_CHECKOUT':
      return {
        ...state,
        isRemoving: true,
        currentLineItem: action.currentLineItem,
      };
    case 'UPDATE_QUANTITY_CHECKOUT':
      return {
        ...state,
        isAdding: true,
        currentLineItem: action.currentLineItem,
      };
    case 'UPDATE_CHECKOUT_DONE':
      return {
        ...state,
        checkout: action.checkout,
        isAdding: false,
        isRemoving: false,
      };
    case 'TOGGLE_CART':
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };

    default:
      return state;
  }
};
